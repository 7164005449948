import React from "react"
import DynamicComponent from "../components/DynamicComponent"
import Layout from "../components/Layout"
import StoryblokService from '../utils/storyblok-service'
import { languages } from 'utils/lang'

export default class extends React.Component {
  state = {
    story: {
      content: JSON.parse(this.props.pageContext.story.content)
    },
    t: null
  }

  async getInitialStory() {
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.pageContext.story.full_slug}`)
    return story
  }

  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)

    let pathname = this.props.location.pathname;
    languages.forEach(el => {
      if(pathname.split("/")[1] === el){
        if(el === 'en'){
          this.setState({t: 'default'})
        }else {
          this.setState({t: el})
        }
      }
    })
  }

  render() {
    return (
      <Layout location={this.props.location} t={this.state.t}
        story={this.state.story}>
        <DynamicComponent blok={this.state.story.content}
                          key={this.props.pageContext.story._uid} 
                          story={this.state.story}
                          location={this.props.location}
                          t={this.state.t}
                        />
      </Layout>
    )
  }
}
